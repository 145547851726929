<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1017.01 164.11" width="180" height="28">
    <path
      class="letter"
      d="M893.36,31.92c-6.06-7.72-13.45-14.05-22.01-18.89-10.6-5.99-22.02-9.06-34.21-9.26-5.31-.09-10.58.33-15.8,1.2-6.42,1.08-12.65,2.85-18.4,6-9.84,5.41-18.43,12.43-25.36,21.21-12.85,16.28-17,35.01-14.64,55.39,3.33,20.11,12.97,36.46,29.26,48.77,6.73,5.09,14.16,8.83,22.32,11.09,10.16,2.83,20.43,3.58,30.91,2.16,14.09-1.9,26.69-7.24,37.33-16.6,16.92-14.88,25.6-33.73,25.84-54.8-.12-6.51-.43-11.44-1.47-16.31-2.35-11.02-6.8-21.11-13.76-29.97ZM835.61,123.86c-20.6.75-46.85-15.85-46.98-46.68-.11-26.45,21.14-47.36,46.58-47.04,26.55-.05,46.79,20.59,47.03,46.61.23,25.87-21.74,47.83-46.63,47.1Z"
    />
    <path
      class="letter"
      d="M1016.99,148.24c-.08-.69-.13-1.41-.38-2.05-.64-1.63-1.38-3.21-2.09-4.81-6.92-15.48-13.85-30.96-20.78-46.44-.5-1.12-1.01-2.23-1.47-3.36-.67-1.62-.44-3.09.85-4.32.88-.85,1.82-1.63,2.73-2.46,3.63-3.33,6.76-7.09,9.31-11.3,6.71-11.07,8.31-22.91,4.84-35.33-3.81-13.59-12.39-23.29-25-29.45-6.72-3.28-13.87-4.64-21.33-4.62-13.64.04-27.28,0-40.92.01-1.05,0-2.1.02-3.14.12-1.87.18-3.29,1.57-3.41,3.39-.1,1.57-.1,3.15-.1,4.72,0,21.69,0,43.38,0,65.08s0,43.38,0,65.08c0,1.57,0,3.15.09,4.72.11,1.84,1.47,3.23,3.33,3.5.52.08,1.04.15,1.56.15,4.9.01,9.79.02,14.69,0,.69,0,1.39-.14,2.07-.29,1.31-.28,2.52-1.61,2.64-2.94.12-1.39.16-2.79.17-4.19.02-7.35,0-14.69,0-22.04,0-6.3.02-12.6,0-18.89,0-3.97,1.13-5.32,5.25-5.51.35-.02.7-.02,1.05-.02,4.9,0,9.79,0,14.69,0,.87,0,1.75.08,2.62.15,2.48.22,4.27,1.33,5.07,3.82.26.83.7,1.6,1.06,2.4,6.08,13.56,12.16,27.12,18.24,40.68.57,1.28,1.18,2.54,1.73,3.82.77,1.79,2.13,2.72,4.03,2.89.7.06,1.39.14,2.09.14,5.6,0,11.19.01,16.79-.01.69,0,1.39-.14,2.07-.26.97-.17,1.77-1.31,1.65-2.37ZM982.94,56.17c-2.49,7.55-7.87,12.18-15.83,13.53-1.37.23-2.78.31-4.17.31-5.58.03-11.17.02-16.75,0-.87,0-1.74-.06-2.61-.15-1.39-.15-2.61-1.33-2.77-2.73-.12-1.04-.16-2.09-.16-3.13-.02-4.54,0-9.08,0-13.62h0c0-4.54-.02-9.08.02-13.62,0-1.03.13-2.09.38-3.1.31-1.28,1.2-2.14,2.52-2.37,1.02-.18,2.08-.26,3.12-.26,5.41-.02,10.82-.03,16.23,0,1.39.01,2.79.14,4.17.33,6.1.84,10.7,4.08,13.97,9.17,3.11,4.86,3.66,10.28,1.9,15.62Z"
    />
    <path
      class="letter"
      d="M433.16,144.75c-2.99-6.7-6-13.4-9-20.1-4.5-10.05-8.99-20.1-13.47-30.16-.57-1.27-1.12-2.57-1.5-3.9-.29-1.04-.08-2.12.76-2.91,1.15-1.07,2.37-2.06,3.54-3.12,12.48-11.28,19.12-29.31,13.12-47.84-5.23-16.15-16.52-26.41-32.73-31.23-4.73-1.41-9.63-1.68-14.54-1.68-11.89,0-23.78,0-35.66,0-1.57,0-3.15-.01-4.72.03-.69.02-1.4.12-2.07.28-1.5.37-2.46,1.37-2.74,2.86-.22,1.19-.25,2.43-.26,3.65-.02,9.44-.01,18.89,0,28.33,0,.87.01,1.75.08,2.62.25,3.01,2.4,5.01,5.06,5.04,4.72.05,9.44.06,14.16,0,2.3-.03,4.38-1.37,4.89-4.59.16-1.03.11-2.09.15-3.14.04-1.05.02-2.1.13-3.14.23-2.13,1.84-3.93,3.93-4.41.68-.16,1.38-.28,2.07-.28,5.77-.02,11.54-.08,17.31.03,2.8.05,5.56.57,8.14,1.8,13.42,6.38,14.85,23.66,4.45,32.27-3.19,2.64-6.79,4.28-10.96,4.49-1.74.09-3.49.1-5.24.1-11.89,0-23.78,0-35.66,0-1.4,0-2.8-.02-4.19.07-2.43.16-3.99,1.59-4.18,3.89-.13,1.57-.12,3.14-.12,4.72,0,10.67,0,21.34,0,32,0,10.49,0,20.99,0,31.48,0,1.4-.02,2.8.05,4.2.03.69.17,1.39.37,2.05.33,1.06,1.1,1.77,2.15,2.11.49.16,1.02.28,1.54.28,5.42.02,10.84,0,16.26,0,.17,0,.35-.05.52-.07,1.82-.22,3.18-1.57,3.34-3.48.13-1.57.12-3.14.12-4.72,0-12.42,0-24.83.01-37.25,0-1.57.03-3.15.14-4.71.11-1.54,1.02-2.61,2.57-2.9,1.36-.25,2.77-.4,4.15-.42,5.07-.05,10.14-.02,15.21-.02.7,0,1.4.02,2.1.03,2.5.04,4.31,1.01,5.32,3.47,1.39,3.4,2.95,6.72,4.44,10.08,5.18,11.66,10.39,23.31,15.54,34.98,2.13,4.84,2.61,5.04,7.9,5.06,4.55.02,9.09.01,13.64,0,.87,0,1.75-.03,2.62-.1,1.77-.15,2.8-1.63,2.29-3.32-.25-.83-.62-1.63-.97-2.42Z"
    />
    <path
      class="letter"
      d="M767.73,28.34c.97-.11,1.96-1.19,2.09-2.11.07-.52.15-1.04.15-1.56,0-5.77,0-11.54,0-17.31,0-.17-.02-.35-.03-.52-.12-1.83-1.15-3.02-2.88-3.15-1.57-.12-3.14-.13-4.71-.13-33.73,0-67.47,0-101.2,0-1.22,0-2.45,0-3.67.05-.52.02-1.03.15-1.55.25-.82.16-1.58.93-1.7,1.78-.12.86-.23,1.73-.24,2.6-.02,5.07-.02,10.14,0,15.21,0,.87.07,1.75.18,2.61.15,1.25,1.17,2.32,2.39,2.42,1.57.12,3.14.17,4.71.17,8.91.02,17.83,0,26.74.02,1.57,0,3.15.03,4.7.27,2.1.32,3.89,2.29,3.99,4.3.1,1.92.14,3.84.14,5.76,0,34.1,0,68.19,0,102.29,0,1.75-.02,3.5.06,5.24.1,2.12,1.23,3.25,3.24,3.37.52.03,1.05.04,1.57.04,6.82,0,13.63,0,20.45,0,.52,0,1.05,0,1.57-.02,2.42-.12,3.34-1.06,3.43-3.66.06-1.57.04-3.15.04-4.72,0-16.96,0-33.92,0-50.88,0-17.49,0-34.97,0-52.46,0-1.57-.03-3.15.11-4.72.25-2.68,2.06-4.47,4.78-4.71,1.56-.14,3.14-.12,4.71-.12,8.91-.01,17.83,0,26.74-.02,1.39,0,2.79-.13,4.17-.29Z"
    />
    <path
      class="letter"
      d="M645.39,27.7c-4.2-7.2-9.87-12.9-17-17.1-7.13-4.19-14.9-6.29-23.29-6.29h-46.37c-.98,0-1.86.38-2.63,1.15-.77.77-1.15,1.72-1.15,2.83v19.31c0,1.12.38,2.06,1.15,2.83s1.65,1.15,2.63,1.15h46.37c5.31,0,9.89,1.89,13.74,5.67,3.85,3.78,5.77,8.39,5.77,13.85s-1.92,9.86-5.77,13.64c-3.85,3.77-8.43,5.66-13.74,5.66h-46.37c-.98,0-1.86.39-2.63,1.16-.77.77-1.15,1.71-1.15,2.83v73.23c0,.98.38,1.85,1.15,2.62s1.65,1.15,2.63,1.15h16.15c1.12,0,2.07-.34,2.84-1.04.76-.7,1.15-1.61,1.15-2.73v-46.79c0-1.26.28-2.1.84-2.52.56-.42,1.61-.63,3.15-.63h22.24c8.39,0,16.16-2.1,23.29-6.3,7.13-4.19,12.8-9.86,17-16.99,4.19-7.14,6.29-14.9,6.29-23.29s-2.1-16.19-6.29-23.4Z"
    />
    <g>
      <path
        class="logo"
        d="M442.46,42.76c3.54,1.43,7.24,2.32,11.02,2.9,5.18.8,10.39.98,15.61.74,6.48-.3,12.95-.7,19.43-1.1,5.63-.35,11.25-.81,16.88-1.14,12.25-.71,24.5-1.37,36.75-2.03,5.96-.32,5.37-.4,4.19-5.65-2.28-10.18-8.21-17.46-17.9-21.55-1.3-.54-2.62-1.03-3.96-1.46-4.46-1.43-9.06-2.14-13.71-2.42-6.2-.37-12.41-.12-18.6.2-8.17.42-16.33.98-24.49,1.48,0-.02,0-.04,0-.06-1.55.08-3.1.16-4.65.25-8.59.5-17.17.99-25.76,1.5-4.64.28-9.29.58-13.93.89-.81.05-1.33.65-1.18,1.41.37,1.93.61,3.92,1.24,5.77,3.28,9.62,9.6,16.45,19.06,20.27"
      />
      <path
        class="logo"
        d="M545.3,113.51c-3.29-1.65-6.77-2.79-10.37-3.52-6.53-1.31-13.12-1.73-19.76-1.37-1.12.06-2.25.16-3.37.22-9.71.48-19.42.95-29.12,1.43,0,.02,0,.05,0,.07-7.17.43-14.34.87-21.52,1.28-6.89.39-13.79.74-20.68,1.13-.84.05-1.67.2-2.5.33-.49.08-.97.73-.89,1.26.2,1.39.28,2.82.67,4.16,2.75,9.6,8.5,16.72,17.56,21.05,3.58,1.71,7.38,2.76,11.27,3.49,5.29.99,10.65,1.19,16,.97,8.3-.34,16.61-.81,24.9-1.29,6.75-.39,13.49-.91,20.24-1.31,7.03-.42,14.07-.77,21.1-1.16,3.38-.19,6.75-.41,10.12-.63.56-.04,1.12-.09,1.68-.16.69-.08,1.19-.75,1.16-1.5-.09-2.57-.82-4.99-1.63-7.4-2.58-7.75-7.58-13.4-14.85-17.05"
      />
      <path
        class="logo"
        d="M449.87,99.31c4.23,1.65,8.62,2.62,13.13,3.08,5.34.55,10.68.72,16.04.23,1.68-.15,3.38-.16,5.07-.25,5.77-.32,11.54-.64,17.31-.99,7.88-.48,15.75-1.01,23.63-1.49,8.86-.54,17.72-1.08,26.59-1.58,2.51-.14,2.61-.7,2.3-2.87-.18-1.25-.48-2.49-.8-3.72-2.48-9.28-7.99-16.03-16.79-19.99-1.28-.58-2.6-1.1-3.93-1.54-4.3-1.44-8.72-2.27-13.24-2.56-8.6-.55-17.19-.03-25.77.4-2.68.13-5.34.33-8.02.49-2.81.16-5.63.3-8.44.46,0,.01,0,.03,0,.05-6.75.39-13.51.79-20.26,1.17-6.75.38-13.51.73-20.27,1.11-1.83.1-3.65.28-5.48.46-.41.04-.84.13-1.21.31-.21.1-.44.42-.44.64.01,2.42.55,4.76,1.4,7.01,3.53,9.34,9.84,15.98,19.2,19.62"
      />
    </g>
    <g>
      <path
        class="letter"
        d="M205.41,10.42c-3.39-4.05-7.43-7.34-11.91-10.1-.22-.13-.46-.29-.7-.31-.23-.02-.54.06-.69.21-.29.3-.52.68-.72,1.05-1.13,2.12-2.24,4.24-3.37,6.36-9.72,18.32-19.24,36.75-29.08,55.01-4.68,8.67-9.2,17.44-13.79,26.16-2.16,4.1-4.11,8.29-5.8,12.61-2.41,6.17-4.25,12.49-5.08,19.08-.97,7.63-.25,15.05,2.46,22.26.33.88.69,1.76,1.12,2.6,2.28,4.56,5.23,8.64,8.78,12.3,2.53,2.61,5.49,4.62,8.61,6.47.26-.17.53-.26.65-.45.33-.5.59-1.04.87-1.57,14.38-27.3,28.76-54.6,43.14-81.9.36-.68.69-1.38,1.02-2.08.52-1.08.99-2.19,1.54-3.25,2.35-4.48,4.53-9.04,6.36-13.77,2.42-6.26,4.3-12.66,5.07-19.35.49-4.28.52-8.55-.13-12.82-1.06-6.95-3.83-13.13-8.34-18.52Z"
      />
      <path
        class="letter"
        d="M135.94,70.52c1.09,1.26,3.23,1.25,4.33-.02.39-.45.7-.97.99-1.49,2.71-4.96,5.42-9.92,8.11-14.89.41-.75.75-1.54,1.04-2.34.67-1.83.68-3.68.11-5.55-.38-1.24-.9-2.4-1.51-3.54-2.62-4.91-5.23-9.82-7.84-14.74-2.25-4.24-4.47-8.48-6.72-12.72-.4-.75-.83-1.5-1.31-2.21-.6-.9-1.44-1.47-2.52-1.6-.93-.11-1.87-.2-2.81-.21-3.08-.03-6.17,0-9.25,0h0c-3,0-5.99,0-8.99,0-.6,0-1.2-.03-1.8.04-.5.06-1.01.19-1.48.38-.68.28-1.06.84-1.17,1.57-.1.69-.03,1.37.29,2,.35.69.72,1.36,1.09,2.04,9.11,16.59,18.21,33.17,27.32,49.76.41.75.8,1.51,1.24,2.25.26.44.54.88.87,1.26Z"
      />
      <path
        class="letter"
        d="M302.94,77.34c-6.6-3.13-15.15-6.5-25.65-10.1-2.87-1.15-6.04-2.52-9.53-4.12-5.88-2.69-9.81-4.67-11.79-5.95-4.96-3.11-7.44-6.8-7.44-11.05,0-2.69,1.1-5.35,3.29-7.97,2.2-2.62,5.21-4.85,9.03-6.69,5.1-1.98,10.55-2.97,16.36-2.97,4.96,0,9.92.71,14.88,2.13,4.25,1.13,9.28,3.33,15.09,6.59.71.43,1.41.64,2.13.64.85,0,1.49-.21,1.91-.64l.21-.21s-.06.02-.08.03l2.42-3.43c4.11-5.81,6.51-9.49,7.23-11.05.14-.28,0-.78-.43-1.49-.42-.71-.99-1.34-1.7-1.91-3.83-3.26-7.94-6.02-12.33-8.29-4.69-2.21-10.2-3.87-16.5-5.01-7.15-1.6-13.83-1.89-19.87-1.39-.2,0-.41,0-.61.02-.54.06-1.07.13-1.6.2-.43.05-.86.11-1.29.16-.07,0-.13.02-.2.03-10.32,1.44-18.45,5.03-23.39,7.73-1.06.57-2.08,1.17-3.06,1.8-1.47.93-2.26,1.54-2.26,1.54h0c-3.57,2.6-6.57,5.61-9,9.03-2.85,4.02-4.76,8.45-5.73,13.28-.86,3.05-1.29,6.17-1.29,9.37,0,6.66,1.98,13.11,5.95,19.34,3.26,4.67,8.25,8.61,14.98,11.79,6.6,3.13,15.15,6.5,25.65,10.1,2.87,1.15,6.04,2.52,9.53,4.12,5.88,2.69,9.81,4.67,11.79,5.95,4.96,3.11,7.44,6.8,7.44,11.05,0,2.69-1.1,5.35-3.29,7.97-2.2,2.62-5.21,4.85-9.03,6.69-5.1,1.98-10.55,2.97-16.36,2.97-4.96,0-9.92-.71-14.88-2.13-4.25-1.13-9.28-3.33-15.09-6.59-.71-.43-1.41-.64-2.13-.64-.85,0-1.49.21-1.91.64l-.21.21s.06-.02.08-.03l-2.42,3.43c-4.11,5.81-6.51,9.49-7.23,11.05-.14.28,0,.78.43,1.49.42.71.99,1.34,1.7,1.91,3.83,3.26,7.94,6.02,12.33,8.29,4.82,2.26,10.49,3.96,17,5.1,6.52,1.13,13.18,1.56,19.98,1.28,9.35-.99,17.64-3.58,24.86-7.76,7.23-4.18,12.86-9.39,16.89-15.62,4.04-6.24,6.06-12.82,6.06-19.76s-1.98-13.11-5.95-19.34c-3.26-4.67-8.25-8.61-14.98-11.79Z"
      />
      <path
        class="letter"
        d="M81.19,77.3c-6.6-3.13-15.15-6.5-25.65-10.1-2.87-1.15-6.04-2.52-9.53-4.12-5.88-2.69-9.81-4.67-11.79-5.95-4.96-3.11-7.44-6.8-7.44-11.05,0-2.69,1.1-5.35,3.29-7.97,2.2-2.62,5.21-4.85,9.03-6.69,5.1-1.98,10.55-2.97,16.36-2.97,4.96,0,9.92.71,14.88,2.13,4.25,1.13,9.28,3.33,15.09,6.59.71.43,1.41.64,2.13.64.85,0,1.49-.21,1.91-.64l.21-.21s-.06.02-.08.03l2.42-3.43c4.11-5.81,6.51-9.49,7.23-11.05.14-.28,0-.78-.43-1.49-.42-.71-.99-1.34-1.7-1.91-3.83-3.26-7.94-6.02-12.33-8.29-4.82-2.26-10.49-3.96-17-5.1-6.52-1.13-13.18-1.56-19.98-1.28-9.35.99-17.64,3.58-24.86,7.76-7.23,4.18-12.86,9.39-16.89,15.62C2.02,34.04,0,40.62,0,47.56s1.98,13.11,5.95,19.34c3.26,4.67,8.25,8.61,14.98,11.79,6.6,3.13,15.15,6.5,25.65,10.1,2.87,1.15,6.04,2.52,9.53,4.12,5.88,2.69,9.81,4.67,11.79,5.95,4.96,3.11,7.44,6.8,7.44,11.05,0,2.69-1.1,5.35-3.29,7.97-2.2,2.62-5.21,4.85-9.03,6.69-5.1,1.98-10.55,2.97-16.36,2.97-4.96,0-9.92-.71-14.88-2.13-4.25-1.13-9.28-3.33-15.09-6.59-.71-.43-1.41-.64-2.13-.64-.85,0-1.49.21-1.91.64l-.21.21s.06-.02.08-.03l-2.42,3.43c-4.11,5.81-6.51,9.49-7.23,11.05-.14.28,0,.78.43,1.49.42.71.99,1.34,1.7,1.91,3.83,3.26,7.94,6.02,12.33,8.29,4.69,2.21,10.2,3.87,16.5,5.01,7.15,1.6,13.83,1.89,19.87,1.39.2,0,.41,0,.61-.02.54-.06,1.07-.13,1.6-.2.43-.05.86-.11,1.29-.16.07,0,.13-.02.2-.03,10.32-1.44,18.45-5.03,23.39-7.73,1.06-.57,2.08-1.17,3.06-1.8,1.47-.93,2.26-1.54,2.26-1.54h0c3.57-2.6,6.57-5.61,9-9.03,2.85-4.02,4.76-8.45,5.73-13.28.86-3.05,1.29-6.17,1.29-9.37,0-6.66-1.98-13.11-5.95-19.34-3.26-4.67-8.25-8.61-14.98-11.79Z"
      />
    </g>
  </svg>
</template>

<style lang="scss" scoped>
.logo {
  fill: rgb(var(--v-theme-logo)) !important;
}
.letter {
  fill: currentColor;
}
</style>
